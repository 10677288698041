<template>
	<div class="view">
		<div class="main">
			<!-- TODO 하단 검은색 레이어 추가 -->
			<b-tabs justified v-model="tabIndex">
				<b-tab>
					<!-- 건놀사 -->
					<template v-slot:title>
						<img src="@/assets/logo-01.png" alt="건축놀이사무소" />
					</template>
					<b-jumbotron fluid container-fluid class="even">
						<template v-slot:header
							>학교공간혁신을 위한 공간감수성 수업,<br />
							'건축놀이사무소'</template
						>
						<template v-slot:lead>
							동화 속 주인공들이 건축물을 의뢰하면, 학생들이 건축전문가가 되어
							<br class="desktop-only" />의뢰를 해결하는 팀프로젝트형 프로그램 건축놀이사무소, <br class="desktop-only" />다양한 교과와 연계 가능한 건축놀이를 통해 학생들은 <br class="desktop-only" />공간을 읽고 활용할 수 있는 공간감수성을 높이게 됩니다.
						</template>
						<iframe width="600" height="330" src="https://player.vimeo.com/video/667028916?h=5ba1f2632d" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</b-jumbotron>
					<b-jumbotron fluid container-fluid class="odd">
						<template v-slot:header>건축놀이사무소의 특장점</template>
						<template v-slot:lead>건축놀이사무소로 수업하면 이런 장점이 있습니다.</template>
						<!-- 데스크탑 뷰 -->
						<b-card-group deck class="transparent">
							<b-card :img-src="require('@/assets/landing-page/icon7.png')" title="">학교 공간을 다시 보는 <br class="desktop-only" /><span class="bold">공간중심 사고력 향상</span></b-card>
							<b-card :img-src="require('@/assets/landing-page/icon8.png')" title="">실제 공간의 변화로 이어지는 <span class="bold">혁신수업</span></b-card>
							<b-card :img-src="require('@/assets/landing-page/icon9.png')" title="">역할 중심의 놀이로 이뤄지는 <br class="desktop-only" /><span class="bold">프로젝트중심 수업</span></b-card>
						</b-card-group>
						<!-- 모바일 뷰 -->
						<b-card-group columns class="transparent">
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon7.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 학교 공간을 다시 보는 <br class="desktop-only" /><span class="bold">공간중심 사고력 향상</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon8.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 실제 공간의 변화로 이어지는 <span class="bold">혁신수업</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon9.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 역할 중심의 놀이로 이뤄지는 <br class="desktop-only" /><span class="bold">프로젝트중심 수업</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
						</b-card-group>
					</b-jumbotron>

					<b-jumbotron fluid container-fluid class="even wide thirds">
						<template v-slot:header>건축놀이사무소 수업후기</template>
						<template v-slot:lead>선생님들이 건축놀이사무소로 공간교육을 하는 이유 </template>
						<b-card-group deck>
							<b-card title="">
								<b-card-text>
									학교 공간을 탐색해 보고, 건축놀이를 만드는 과정에 대해 고민하는 시간을 갖고 나니, 학생들이 스스로 공간을 찾고 건축놀이를 만들어 나가기 시작했어요. 교사에게 의존하지 않고, 서로에게 퍼실리테이터가 되어 건축물을 완성해 내는 것을 보고 깜짝 놀랐습니다.</b-card-text
								>
								<template v-slot:footer><span class="bold">부산 **초등학교</span><br />이OO 선생님</template>
							</b-card>
							<b-card title="">
								<b-card-text> 아이들은 혼자 공부할 때보다 서로 소통하고 협동할 때 창의력을 더 잘 발휘하는 것 같아요. 건축주의 재미있는 의뢰를 받고 공간에 대한 상상력을 총동원해서 문제를 해결해가는 과정에서 그 어떤 수업보다 활발한 의견교환과 웃음이 있었던 수업이었습니다.</b-card-text>
								<template v-slot:footer><span class="bold">구리 **초등학교</span><br />김OO 선생님</template>
							</b-card>
							<b-card title="">
								<b-card-text>
									공간은 그 안에서 활동하는 인간의 사고과정에 큰 영향을 미칩니다. 아이들이 가장 많은 시간을 보내는 학교에 대한 긍정적인 경험을 하게 만드는 것도 교육입니다.<br />
									건축놀이사무소는 늘상 똑같아 보이는 학교라는 공간을 새롭게 바라보고, 공간과 공간의 요소들을 활용하여 즐거운 건축놀이를 하게 해 줍니다.</b-card-text
								>
								<template v-slot:footer><span class="bold">춘천 **초등학교</span><br />한OO 선생님</template>
							</b-card>
						</b-card-group>
					</b-jumbotron>
				</b-tab>
			</b-tabs>
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
// 로그인 페이지
import Footer from '@/components/Footer';

// import AlertToast from "@/components/GenericAlertToast"

export default {
	name: 'Landing',
	data() {
		return {
			tabIndex: 0,
			login: false,
		};
	},
	mounted() {
		if (this.$store.state.token.length) {
			this.login = true;
			// console.log(this.login, this.$store.state.token);
		}
	},
	beforeDestroy: function() {
		// 핸들러 제거하기
		clearInterval(this.slogalInterval);
	},
	computed: {
		slogan() {
			return '‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다.';
			// '공간 리터러시 수업 ‘건축놀이사무소’로 학교공간을 혁신하고<br>디지털 통일 수업 ‘월페커즈’로 미래교육 문화를 만들어 나갑니다.'
		},
	},
	methods: {
		swapSlogan: function() {
			[...document.getElementsByClassName('carousel-slogan')].forEach((e) => {
				e.classList.toggle('hidden');
			});
		},
		hideWorkshopSchedule: function() {
			document.querySelector('.workshop-schedule').style.display = 'none';
		},
	},
	components: {
		Footer,
		// AlertToast
	},
	metaInfo: {
		// 페이지 제목 설정
		title: 'NOLGONG SCHOOL',
		titleTemplate: null,
		meta: [
			// SEO 설정
			{
				name: 'description',
				content:
					'학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소> | ‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'description',
			},
			{ name: 'keywords', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			// SNS 설정
			{
				property: 'og:type',
				content: 'website',
				vmid: 'og:type',
			},
			{
				property: 'og:title',
				content: 'NOLGONG SCHOOL',
				vmid: 'og:title',
			},
			{
				property: 'og:description',
				content: '‘NOLGONG SCHOOL’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'og:description',
			},
			{
				property: 'og:url',
				content: 'https://nolgongschool.com/',
				vmid: 'og:url',
			},
			{
				property: 'og:image',
				content: 'https://nolgongschool.com/static/social_image_horizontal.png',
				vmid: 'og:image',
			},
			{
				property: 'og:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'og:image:alt',
			},
			{
				property: 'og:image:width',
				content: '800',
				vmid: 'og:image:width',
			},
			{
				property: 'og:image:height',
				content: '400',
				vmid: 'og:image:height',
			},
			{
				property: 'og:locale',
				content: 'ko_KR',
				vmid: 'og:locale',
			},
			{
				property: 'og:site_name',
				content: 'NOLGONG SCHOOL',
				vmid: 'og:site_name',
			},
			{
				property: 'twitter:card',
				content: 'summary',
				vmid: 'twitter:card',
			},
			{
				property: 'twitter:site',
				content: '@nolgong',
				vmid: 'twitter:site',
			},
			{
				property: 'twitter:title',
				content: 'NOLGONG SCHOOL',
				vmid: 'twitter:title',
			},
			{
				property: 'twitter:description',
				content: '‘NOLGONG SCHOOL’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'twitter:description',
			},
			{
				property: 'twitter:image',
				content: 'https://nolgongschool.com/static/social_image_horizontal.png',
				vmid: 'twitter:image',
			},
			{
				property: 'twitter:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'twitter:image:alt',
			},
		],
	},
};
</script>

<style scoped>
.mobile-only {
	display: none;
}
.view {
	/*min-width: 540px;*/
}
.main {
	padding-top: calc(1rem + 40px);
	padding-left: 0;
	padding-bottom: 0;
	padding-right: 0;
	word-break: keep-all;
}
h1 {
	margin-bottom: 36px;
	font-weight: 800;
	font-size: 54px;
}
.lead {
	margin-bottom: 48px;
}
a {
	color: #474747;
}
/* tabs */
.main >>> .nav-tabs img {
	width: 100%;
	max-width: 240px;
}
.main >>> .tabs .nav-tabs {
	padding-top: 2rem;
	background-color: #e3ffde;
	border-bottom: none;
	justify-content: center;
}
.main >>> .tabs .nav-item {
	max-width: 342px;
	background-color: #e3ffde;
}
.main >>> .tabs .nav-link {
	padding-top: 1.5em;
	height: 100%;
	background-color: #/ wallpeckers-assets/;
	border-top-left-radius: 2em;
	border-top-right-radius: 2em;
}
.main >>> .tabs .nav-item:first-of-type {
	z-index: 3;
}
.main >>> .tabs .nav-item:nth-of-type(2) {
	z-index: 2;
}
.main >>> .tabs .nav-item:last-of-type {
	z-index: 1;
}
.main >>> .nav-tabs .nav-link:focus,
.main >>> .nav-tabs .nav-link:hover {
	background: rgba(255, 255, 255, 0.5);
	border-bottom: none;
}
.main >>> .tabs .nav-link.active {
	background-color: #fff;
	border-color: #f6f6f6;
	border-bottom: none;
	box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.1);
}
.jumbotron-carousel >>> .carousel-inner {
	max-height: 800px;
}

.carousel-slogan-wrap {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6) 100%);
	display: flex;
	justify-content: center;
	align-items: flex-end;
}
.carousel-slogan {
	padding-bottom: 6rem;
	font-weight: bold;
	font-size: 1.75rem;
	color: white;
	text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
	transition: opacity 1s ease-in-out;
	opacity: 1;
}
.carousel-slogan.hidden {
	opacity: 0;
	position: fixed;
}
.carousel >>> .carousel-indicators li {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	border: none;
	margin: 1.5rem 0.5rem;
	opacity: 0.3;
	transition: all 0.25s;
}
.carousel >>> .carousel-indicators li.active {
	transform: scale(1.2);
	opacity: 1;
}

.jumbotron {
	margin-bottom: 0;
}
.jumbotron .container-fluid {
	max-width: 1024px;
}
.jumbotron.wide .container-fluid {
	max-width: 100%;
}
.jumbotron.odd {
	background: #e3ffde;
	min-height: 640px;
}
.jumbotron.even {
	background: #fff;
	min-height: 640px;
}
.jumbotron-carousel {
	/*width: 100%;*/
	padding: 0;
}
.jumbotron-carousel .container-fluid {
	max-width: 100%;
	padding: 0;
}
.jumbotron.splash {
	display: flex;
	align-items: center;
	min-height: 400px !important;
}
.jumbotron.splash .lead {
	font-weight: 600;
	font-size: 1.5em;
	margin-bottom: 120px;
	white-space: pre-wrap;
}
.jumbotron.kakaotalk {
	background: #ffd700;
}
.jumbotron.kakaotalk .lead {
	font-size: 36px;
	font-weight: bold;
	margin-bottom: auto;
}
.kakao-logo {
	max-height: 50px;
	margin-left: 16px;
}
.card-title {
	font-weight: bold;
}
.card {
	word-break: break-all;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.card,
.card-footer {
	border-radius: 10px;
}
.card-footer {
	background-color: white;
	border-top: none;
}
.jumbotron.wide .card-title {
	margin-top: 0.5em;
}
.jumbotron.wide .card-text::before,
.jumbotron.wide .card-text::after {
	/*font-size: 125%;*/
	font-style: oblique;
}
.jumbotron.wide .card-text::before {
	content: '“';
}
.jumbotron.wide .card-text::after {
	content: '”';
}
.transparent .card {
	background: transparent;
	border: none;
	box-shadow: none;
}
.card-columns.transparent {
	display: none;
}
.card-text {
	text-align: justify;
	padding-top: 1em;
	padding-bottom: 1em;
}
.card-img {
	padding: 1em;
}
.call-to-action {
	margin-top: 1em;
	font-size: 2em;
	padding: 0.25rem 0.75rem 0.5rem;
}
.call-to-action a {
	vertical-align: text-bottom;
	color: #f8f8f8;
}

.workshop-schedule {
	transition: all 1.5s;
	opacity: 0;
	z-index: 10;
	position: fixed;
	top: 80px;
	right: -200px;
	text-align: right;
	font-size: 1.5em;
}
.workshop-schedule.visible {
	opacity: 1;
	right: 1rem;
}
.workshop-schedule img {
	max-height: 144px;
	max-width: 144px;
}
.icon-close {
	opacity: 0;
	transition: opacity 3s cubic-bezier(1, -0.15, 0.5, 1);
	color: gray;
	line-height: 1em;
	margin-right: -6px;
	margin-bottom: -6px;
}
.workshop-schedule.visible .icon-close {
	opacity: 1;
}
.icon-close:hover {
	cursor: pointer;
}

/* popup */

.pop-up {
	position: absolute;
	z-index: 10;
	top: 82px;
	right: 54px;
	width: 300px;
}
.pop-up.one {
	left: 54px;
}
.pop-up.two {
	right: 54px;
}

/* .pop-up.one > div {
	margin: 0;
	padding: 0;
} */

.popup-btn-wrap .popup-btn:first-child {
	border: 0;
	border-right: 0.5px solid #ccc;
}
.popup-btn-wrap .popup-btn {
	width: 50%;
	height: 46px;
	font-size: 14px;
	color: #000;
	border: 0;
	background-color: #f2f2f2;
}

/* 화면 사이즈 별로 캐러셀 이미지 위치 조정 */
/* 가로 1920 이상 기준 */
.jumbotron-carousel >>> .carousel-inner .img-fluid {
	transform: translateY(calc((1920px - 100vw) * 0.25));
}
@media (max-width: 1920px) {
	.jumbotron-carousel >>> .carousel-inner .img-fluid {
		transform: none;
	}
}

@media (max-width: 1440px) {
	.jumbotron-carousel >>> .carousel-inner {
		max-height: 620px;
	}
}
@media (max-width: 1200px) {
	h1 {
		font-size: 44px;
	}
}
@media (max-width: 991px) {
	.desktop-only {
		display: none;
	}
	.main {
		padding-top: 48px;
	}
	h1 {
		font-size: 42px;
	}
	.carousel-slogan {
		font-size: 1.5rem;
		padding-bottom: 5rem;
	}
	.carousel >>> .carousel-indicators li {
		width: 8px;
		height: 8px;
		border-radius: 8px;
		margin: 1.25rem 0.5rem;
	}

	.lead {
		font-size: 1.2rem;
	}
	.jumbotron.splash .lead {
		font-size: 1.3em;
	}
	.jumbotron.wide.halves .card {
		min-width: 40%;
		margin-bottom: 1rem;
	}
	.jumbotron.wide .card-deck {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}
@media (max-width: 768px) {
	.mobile-only {
		display: block !important;
	}
	.logo {
		max-width: 80vw;
	}
	h1 {
		font-size: 36px;
	}
	iframe {
		max-width: 90vw;
		max-height: 50vw;
		width: 560px;
		height: 312px;
	}
	.jumbotron-carousel >>> .carousel-inner {
		min-height: 480px;
	}
	.jumbotron-carousel >>> .carousel-inner .img-fluid {
		height: 480px;
		max-width: 768px !important;
		width: auto !important;
		transform: translateX(calc((100vw - 768px) / 2));
	}
	.carousel-slogan {
		font-size: 1.2rem;
		padding-bottom: 4rem;
	}
	.jumbotron.wide .card-deck {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.jumbotron.wide.thirds .card {
		min-width: 80%;
		margin-bottom: 1rem;
	}
	.card-deck .card {
		margin-right: 10px;
		margin-left: 10px;
	}
	.jumbotron.kakaotalk .lead {
		font-size: 24px;
	}
	.kakao-logo {
		height: 32px;
		margin-left: 8px;
	}
	.jumbotron.odd,
	.jumbotron.even {
		min-height: 560px;
	}
	.pop-up.one,
	.pop-up.two {
		left: 50%;
		right: unset;
		transform: translateX(-50%);
	}
	.pop-up.two {
		top: 490px;
	}
	.pop-up.login + .pop-up.two {
		top: 704px;
	}
}
@media (max-width: 667px) {
	h1 {
		font-size: 30px;
	}
}
@media (max-width: 576px) {
	.main {
		padding-top: calc(1rem + 30px);
	}
	h1 {
		font-size: 28px;
	}
	.card-deck .card {
		flex: 1 0 0%;
		margin-right: 5px;
		margin-bottom: 0;
		margin-left: 5px;
	}
	.card-deck {
		display: flex;
		flex-flow: row wrap;
		margin-right: 0px;
		margin-left: 0px;
	}
	.card-deck.transparent {
		display: none;
	}
	.card-columns.transparent {
		display: block;
	}
	.card-img {
		padding: 0;
	}
	iframe {
		width: 500px;
		height: 278px;
	}
	.jumbotron.odd,
	.jumbotron.even {
		min-height: 480px;
	}
	.jumbotron.wide .card-body {
		padding-top: 0;
		padding-bottom: 0;
	}
	.jumbotron.kakaotalk .lead {
		font-size: 20px;
	}
	.main >>> .tabs .nav-link {
		padding-top: 2vw;
		border-top-left-radius: 1em;
		border-top-right-radius: 1em;
	}
}
@media (max-width: 500px) {
	.jumbotron.wide.halves .card {
		min-width: 80%;
	}
}
@media (max-width: 400px) {
	.main >>> .transparent .card-body {
		padding-top: 0;
		padding-bottom: 0;
	}
}
</style>
